<template>
  <div class="row">
    <div class="d-none d-md-block col-md-3 col-lg-4"></div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="p-5">
        <b-form @submit="login">
          <b-form-group label-for="input-senha" class="mt-3">
            <b-input
              id="input-senha"
              placeholder="informe sua senha"
              type="password"
              style="text-align: center"
              v-model="senha"
              ref="senha"
            />
          </b-form-group>
          <div v-if="msg != ''" class="text-center text-danger p-2">
            <i class="fa fa-exclamation-triangle"></i> {{ msg }}
          </div>
          <button class="btn btn-secondary btn-block">
            <i class="fa fa-key"></i>
            Entrar
          </button>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      senha: "",
      msg: "",
    };
  },
  mounted() {
    this.$refs.senha.focus();
  },
  methods: {
    async login(evt) {
      evt.preventDefault();
      if (this.senha.trim() == "") {
        this.msg = "senha inválida!";
        return;
      }
      this.$store
        .dispatch("auth/auth", { senha: this.senha, tipo: "moto" })
        .then((x) => {
          console.log("op", x);
          if (x.success) {
            this.msg = "logado!";
            // location.href="/pedidos"
            try {
              this.$router.push("/pedidos");
            } catch (e) {
              location.href="/pedidos";
            }
          } else {
            this.msg = "usuário não encontrado!";
          }
        })
        .catch(() => {
          this.msg = "usuário não encontrado!";
        });
    },
  },
};
</script>